import { useCallback, memo, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Switch, Route, useRouteMatch, useParams } from 'react-router-dom';
import { downloadByData, formatBytesStructured } from 'utils';
import Hint from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import Placeholder from 'components/Placeholder';
import { Button, Card, Icon, FileInput, withModal, Modal } from 'components/semantic';
import { Item } from 'components/Summary';
import { toast } from 'components/Toast';
import { newESEnabled } from 'config/feature-flags';
import request, { API_VERSION } from 'utils/request';
import { withTracker } from 'utils/tracker';
import { APIError, useAPI, useTransform, useLocalState } from 'utils/use-api';
import { useAppId, useCurrentApp } from 'App/Application';
import { useRuntime, useClusters } from '..';
import InstanceCard from '../InstanceCard';
import { ClusterInfo, isType } from '../types';
import styles from './index.module.scss';
import ESStats from './InstanceStats';
import ScaleModal from '../ScaleModal';

export type ESClusterInfo = ClusterInfo<'es'>;
export const useInstanceId = () => {
  return useParams<{ id: string }>().id;
};
export const useElasticsearch = () =>
  useTransform(
    useClusters(),
    useCallback((clusters) => (clusters ? clusters.filter(isType('es')) : []), [])
  );

const MAX_FILE_SIZE = 10 * 1024 * 1024;
const DictionaryManage = withModal<{ id: number; name: string }>()(({ close, id, name }) => {
  const { t } = useTranslation();
  const [uploaded, { loading, setData: setUploaded, error: existError, reload }] = useLocalState(
    useTransform(
      useAPI<{ uploaded: boolean }>(`/${API_VERSION}/leandb/clusters/${id}/user-dict`),
      useCallback((data) => (data ? data.uploaded : false), [])
    )
  );
  const upload = useCallback(
    async (file: File) => {
      try {
        const form = new FormData();
        form.append('file', file);
        await request(`/${API_VERSION}/leandb/clusters/${id}/user-dict`, {
          method: 'PUT',
          body: form,
        });
        setUploaded(true);
        toast.success(t('action.upload.successfully'));
      } catch (error) {
        toast.error(t('action.upload.failed'), error);
      }
    },
    [id, t, setUploaded]
  );

  const deleteDick = useCallback(async () => {
    try {
      await request(`/${API_VERSION}/leandb/clusters/${id}/user-dict`, {
        method: 'DELETE',
      });
      setUploaded(false);
      toast.success(t('action.delete.successfully'));
    } catch (error) {
      toast.success(t('action.delete.failed'), error.message);
    }
  }, [id, t, setUploaded]);

  const download = useCallback(async () => {
    try {
      const data = await request<string>(
        `/${API_VERSION}/leandb/clusters/${id}/user-dict/download`,
        {
          method: 'GET',
        }
      );
      downloadByData(data, 'dictionary.txt');
    } catch (error) {
      toast.success(t('action.download.failed'), error.message);
    }
  }, [id, t]);

  return (
    <>
      <Modal.Header
        content={
          <Trans
            i18nKey="db.es.customDictionary.header"
            components={{
              code: <code />,
            }}
            values={{
              name,
            }}
          />
        }
      />
      <Modal.Content>
        <APIError
          message={t('db.es.customDictionary.fetchError')}
          error={existError}
          retry={reload}
        />
        {loading && <Placeholder line={4} />}
        {!loading && (
          <>
            {uploaded ? (
              <>
                <>
                  <FileInput
                    className={styles.fileInput}
                    id="uploadFile"
                    hint={t('action.update')}
                    size="small"
                    onChange={(uploadFile) => {
                      if (uploadFile) {
                        if (uploadFile.size > MAX_FILE_SIZE) {
                          toast.error('db.es.customDictionary.max');
                        } else {
                          upload(uploadFile);
                        }
                      }
                    }}
                  />
                  <Button
                    as="label"
                    size="small"
                    icon="download"
                    basic
                    labelPosition="left"
                    content={t('action.download')}
                    onClick={download}
                    className={styles.download}
                  />
                  <Button
                    as="label"
                    icon="trash alt"
                    labelPosition="left"
                    basic
                    content={t('action.delete')}
                    onClick={deleteDick}
                    negative
                    size="small"
                  />
                </>
              </>
            ) : (
              <>
                {t('db.es.customDictionary.empty')}{' '}
                <FileInput
                  className={styles.fileInput}
                  id="uploadFile"
                  hint={t('action.upload')}
                  onChange={(uploadFile) => {
                    if (uploadFile) {
                      if (uploadFile.size > MAX_FILE_SIZE) {
                        toast.error('db.es.customDictionary.max');
                      } else {
                        upload(uploadFile);
                      }
                    }
                  }}
                />
              </>
            )}
            <p className={`${styles.hint} help-block`}>{t('db.es.customDictionary.hint')}</p>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content={t('action.close')} onClick={close} />
      </Modal.Actions>
    </>
  );
});

const ESInstanceCard = memo(
  ({
    instance,
    onDeleted,
    onUpdated,
  }: {
    instance: ESClusterInfo;
    onDeleted: (id: number) => void;
    onUpdated: (id: number, payload: Partial<ESClusterInfo>) => void;
  }) => {
    const { t } = useTranslation();
    const [currentApp] = useCurrentApp();
    const { name, nodeQuota, storageQuota, dataNodes } = instance;
    const [runtimeInfos] = useRuntime('es');
    const [size, unit] = useMemo(() => {
      const quota = nodeQuota.split('-')[1];
      return formatBytesStructured(Number(quota) * 1024 * 1024);
    }, [nodeQuota]);
    const isTest = runtimeInfos?.nodeQuotaMap[nodeQuota].forDevUse;
    const shared = currentApp && instance.appId !== currentApp.appId;

    const usStorage = useMemo(
      () => runtimeInfos?.storageQuotaMap[storageQuota].storage,
      [runtimeInfos?.storageQuotaMap, storageQuota]
    );

    const usQuota = useMemo(
      () => runtimeInfos?.nodeQuotaMap[nodeQuota].memory,
      [nodeQuota, runtimeInfos?.nodeQuotaMap]
    );

    return (
      <InstanceCard
        exportEnabled={false}
        quota={isTest ? 'test' : 'standard'}
        instance={instance}
        onDeleted={onDeleted}
        extraInfo={
          <p>
            <Icon name="info" color="green" />
            {t('db.url')}: <span>ELASTICSEARCH_URL_{name}</span>{' '}
            <Hint content={t('db.url.hint', { db: 'Elasticsearch' })} />
          </p>
        }
        extraOperations={
          <DictionaryManage
            id={instance.id}
            name={instance.name}
            modalProps={{
              trigger: (
                <Button size="small">
                  <Icon name="book user" />
                  {t('db.es.customDictionary.manage')}
                </Button>
              ),
            }}
          />
        }
      >
        <Item name={t('db.scale.dataNodes')} value={dataNodes} />
        {newESEnabled ? (
          <Item name={t('db.quota.memory')} value={usQuota && usQuota / 1024} suffix={'GB'} />
        ) : (
          <Item name={t('db.quota.memory')} value={size} suffix={unit} />
        )}

        <Item
          name={t('db.storage')}
          value={newESEnabled ? usStorage : storageQuota.replace('H', '')}
          suffix={
            <>
              GB{' '}
              {!shared && !newESEnabled && (
                <ScaleModal isTest={!!isTest} instanceData={instance} onUpdated={onUpdated} />
              )}
            </>
          }
        />
      </InstanceCard>
    );
  }
);

const ESList = withTracker(
  memo(() => {
    const appId = useAppId();
    const { t } = useTranslation();
    const [instances, { error, loading, reload, delete: _delete, update: _update }] =
      useElasticsearch();
    const currentAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId === appId),
      [appId, instances]
    );
    const otherAppInstance = useMemo(
      () => instances.filter((instance) => instance.appId !== appId),
      [appId, instances]
    );
    return (
      <Page title={[t('db.es'), t('engine')]}>
        <PageContainer>
          <APIError message={t('db.es.fetchError')} error={error} retry={reload} />
          <Panel title={t('db.instance.currentAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : (
              <>
                {currentAppInstance.length > 0 && (
                  <Card.Group>
                    {currentAppInstance.map((instance) => (
                      <ESInstanceCard
                        instance={instance}
                        key={instance.id}
                        onDeleted={_delete}
                        onUpdated={_update}
                      />
                    ))}
                  </Card.Group>
                )}
                {currentAppInstance.length === 0 && <p>{t('label.none')}</p>}
              </>
            )}
          </Panel>

          <Panel title={t('db.instance.otherAppInstance')}>
            {loading ? (
              <Placeholder line={4} />
            ) : otherAppInstance.length === 0 ? (
              <p>{t('label.none')}</p>
            ) : (
              <Card.Group>
                {otherAppInstance.map((instance) => (
                  <ESInstanceCard
                    instance={instance}
                    key={instance.id}
                    onDeleted={_delete}
                    onUpdated={_update}
                  />
                ))}
              </Card.Group>
            )}
          </Panel>
        </PageContainer>
      </Page>
    );
  })
);

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:id`} component={ESStats} exact />
      <Route component={ESList} exact />
    </Switch>
  );
};
